import { useLocation, Link } from 'react-router-dom';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

// Icons
import { ReactComponent as NavIndicator } from 'Assets/icon-nav-indicator.svg';

// Components
import MapleTypography from 'Components/ds/MapleTypography';

// Hooks
import useBreakpoint from 'Hooks/useBreakpoint';
import useConvertPageLocation from 'Hooks/useConvertPageLocation';

const CONVERT_NAV_ITEMS = {
  stake: {
    title: 'Stake',
    url: '/stake',
  },
  convert: {
    title: 'Convert',
    url: '/convert',
  },
};

const NAV_ITEMS = {
  lend: {
    title: 'Lend',
    url: '/lend',
  },
  portfolio: {
    title: 'Portfolio',
    url: '/portfolio',
  },
  drips: {
    title: 'Drips',
    url: '/drips',
  },
  details: {
    title: 'Details',
    url: '/details',
  },
  stake: {
    title: 'Stake',
    url: '/stake',
    isNew: true,
  },
};

const container = {
  position: 'fixed',
  transform: theme => ({ xs: 'translate(0px, 0px)', md: `translate(-50% ,${theme.spacing(5.2)})` }),
  left: { xs: 0, md: '50%' },
  bottom: theme => ({ xs: theme.spacing(2.5), md: 'auto' }),
  zIndex: 9, // @TODO set custom zIndex into theme
  px: theme => `${theme.spacing(2)} !important`,
};

const stack = {
  backgroundColor: { xs: 'background.white', md: 'transparent' },
  boxShadow: theme => ({ xs: theme.syrupShadows.sm, md: 'none' }),
  py: { xs: 2, md: 0 },
  borderRadius: theme => theme.shape.xxl,
};

const link = {
  transform: 'translate(0px, 0px)',
  transition: 'transform 400ms ease',

  a: {
    textDecoration: 'none',
    fontFamily: theme => theme.typography.label.fontFamily,
    fontWeight: theme => theme.typography.label.fontWeight,
    letterSpacing: theme => ({
      xs: theme.typography.label.xSmall.letterSpacing,
      md: theme.typography.label.medium.letterSpacing,
    }),
    fontSize: theme => ({
      xs: theme.typography.label.xSmall.fontSize,
      md: theme.typography.label.medium.fontSize,
    }),
    lineHeight: theme => ({
      xs: theme.typography.label.xSmall.lineHeight,
      md: theme.typography.label.medium.lineHeight,
    }),
    color: theme => theme.palette.text.soft,
    transitionDelay: '200ms',
    transition: 'transform 500ms ease',
    '&:hover': {
      color: theme => theme.palette.text.strong,
    },
  },

  '&.selected': {
    transitionDelay: '0ms',
    transform: { xs: 'translate(0px, -3px)', md: 'translate(12px, 0px)' },
    a: {
      color: theme => theme.palette.text.strong,
    },
  },
};

const navIndicatorStyles = {
  position: 'absolute',
  top: { xs: '14px', md: '6px' },
  left: { xs: 'calc(50% - 2px)', md: '-2px' },
  opacity: 0,
  transitionDelay: '200ms',
  transition: 'opacity 400ms ease',

  '&.selected': {
    transitionDelay: '0ms',
    opacity: 1,
  },
};

const navIndicatorBulletStyles = {
  width: '4px',
  height: '4px',
  backgroundColor: theme => theme.palette.primary.main,
  borderRadius: theme => theme.shape.round,
};

const newIndicator = {
  backgroundColor: theme => theme.palette.primary.contrastText,
  py: 0.5,
  px: 1,
  borderRadius: theme => theme.shape.lg,
};

const ROUTES_WITH_NAVIGATION_BLOCKED = ['/restricted'];

const NavBar = () => {
  const { pathname } = useLocation();
  const { isDesktopDevice } = useBreakpoint();
  const isConvertPage = useConvertPageLocation();

  const maxWidth = isDesktopDevice ? 'sm' : 'md';
  const isNavigationBlocked = ROUTES_WITH_NAVIGATION_BLOCKED.includes(pathname);
  const navItems = isConvertPage ? CONVERT_NAV_ITEMS : NAV_ITEMS;

  return (
    <Container maxWidth={maxWidth} sx={container}>
      <Stack direction='row' alignItems='center' justifyContent='center' spacing={{ xs: 3, md: 4 }} sx={stack}>
        {Object.keys(navItems).map(page => (
          <Stack key={navItems[page].title} sx={{ position: 'relative' }}>
            <Box sx={navIndicatorStyles} className={pathname === navItems[page].url ? 'selected' : ''}>
              {isDesktopDevice ? <NavIndicator /> : <Box sx={navIndicatorBulletStyles} />}
            </Box>
            <Stack
              sx={link}
              className={pathname === navItems[page].url ? 'selected' : ''}
              direction='row'
              alignItems='center'
              justifyContent='center'
              spacing={1}
            >
              <Link to={isNavigationBlocked || navItems[page].disabled ? undefined : navItems[page].url}>
                {navItems[page].title}
              </Link>
              {navItems[page].isNew && isDesktopDevice && (
                <MapleTypography variant='label' size='xSmall' color='primary.main' sx={newIndicator}>
                  New
                </MapleTypography>
              )}
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Container>
  );
};

export default NavBar;
