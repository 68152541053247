import { useRef } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';

// Icons
import { ReactComponent as SyrupLogo } from 'Assets/logo-syrup-full.svg';
import { ReactComponent as SyrupLogoMobile } from 'Assets/logo-syrup-mobile.svg';
import { RiArrowRightLine } from '@remixicon/react';

// Components
import AppBanner from 'Components/AppBanner';
import MapleButton from 'Components/ds/MapleButton';
import MobileMenu from 'Pages/Home/MobileMenu';
import LandingNav from 'Pages/Home/LandingNav';

// Hooks
import useBreakpoint from 'Hooks/useBreakpoint';

export type NavItem = {
  label: string;
  to: string;
  target?: string;
  rel?: string;
};

// Styles
const styles = {
  py: 4,
  position: 'absolute',
  px: { xs: 4.5, md: 7.5 },
  zIndex: 2,
  width: '100%',
};
const logo = {
  overflow: 'hidden',
  height: '36px',
};

const NAV_ITEMS: NavItem[] = [
  {
    label: 'Docs',
    to: 'https://syrup.gitbook.io/syrup',
    target: '_blank',
    rel: 'noopener noreferrer',
  },
  {
    label: 'FAQ',
    to: 'https://syrup.gitbook.io/syrup/troubleshooting-and-support/faq',
    target: '_blank',
    rel: 'noopener noreferrer',
  },
  {
    label: 'Stake',
    to: '/stake',
  },
  {
    label: 'Convert',
    to: '/convert',
  },
];

const AppBarMarketing = () => {
  const { isDesktopDevice } = useBreakpoint();
  const containerRef = useRef(null);

  return (
    <>
      <AppBanner />
      <Stack ref={containerRef} direction='row' justifyContent='center' sx={styles}>
        <Stack direction='row' justifyContent='space-between' sx={{ width: '100%', maxWidth: '1200px' }}>
          <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={1}>
            {!isDesktopDevice && <MobileMenu containerRef={containerRef} navItems={NAV_ITEMS} />}
            <Link to='/'>
              <Box sx={logo}>{isDesktopDevice ? <SyrupLogo height='36px' width='121px' /> : <SyrupLogoMobile />}</Box>
            </Link>
          </Stack>

          {isDesktopDevice && <LandingNav navItems={NAV_ITEMS} />}

          <Link to='/lend' style={{ textDecoration: 'none' }}>
            <MapleButton size='small' endIcon={<RiArrowRightLine />}>
              Enter App
            </MapleButton>
          </Link>
        </Stack>
      </Stack>
    </>
  );
};

export default AppBarMarketing;
